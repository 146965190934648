import React, { createContext, useState } from 'react'

export const ContextAuthenticate = createContext();

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [Auth, setAuth] = useState({});

  const value = {
    isAuth,
    Auth,
    login: (data) => {
      setIsAuth(true);
      setAuth({
        token: `${data.type_token} ${data.access_token}`
      });
    },
    logout: () => {
      setIsAuth(false);
      setAuth({});
    },
  }

  return (
    <ContextAuthenticate.Provider value={value}>
      {children}
    </ContextAuthenticate.Provider>
  )
}

const shareContext = {
  Provider,
  Consumer: ContextAuthenticate.Consumer
}

export default shareContext;