import axios from "axios";

axios.defaults.baseURL = 'https://www.api.casaandina.ludik.pe';
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';


axios.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem("user-token"));
  if(user && user.access_token) {
    config.headers['Authorization'] = `${user.type_token} ${user.access_token}`;
  }
  return config;
})