import React from 'react'
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';

const ProtectedRoute = ({ children }) => {
  const [userToken] = useLocalStorage('user-token', {});
  return Object.keys(userToken).length
    ? children
    : <Navigate to="/" />
}

export default ProtectedRoute;