import React, { lazy } from "react";
import ProtectedRoute from "routes/ProtectedRoute";

const FullLayout = lazy(() => import("layouts/FullLayout"));

const Login = lazy(() => import("views/Login"));
const Dashboard = lazy(() => import("views/Dashboard"));
const Report = lazy(() => import("views/Report"));
const KeyGenerator = lazy(() => import("views/KeyGenerator"));

const ListRoutes = [
  {
    path: '/',
    exact: true,
    element: <Login />,
  },
  {
    path: "admin",
    element: <FullLayout />,
    children: [
      {
        path: "dashboard",
        exact: true,
        element: <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      },
      {
        path: "report",
        exact: true,
        element: <ProtectedRoute>
          <Report />
        </ProtectedRoute>
      },
      {
        path: "key-generator",
        exact: true,
        element: <ProtectedRoute>
          <KeyGenerator />
        </ProtectedRoute>
      }
    ],
  }
];

export default ListRoutes;
